// frontend/src/App.js
import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import './App.css';

// Lazy load components for better performance
const Login = lazy(() => import('./components/Login'));
const CurrentlyPlaying = lazy(() => import('./components/CurrentlyPlaying'));
const AnalyzeLyrics = lazy(() => import('./components/AnalyzeLyrics'));
const OAuthCallback = lazy(() => import('./components/OAuthCallback')); // Callback Component

function AppRoutes() {
    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/auth/callback" element={<OAuthCallback />} /> {/* Route สำหรับ Callback */}
            <Route 
                path="/currently_playing" 
                element={
                    <ProtectedRoute>
                        <CurrentlyPlaying />
                    </ProtectedRoute>
                } 
            />
            <Route 
                path="/analyze" 
                element={
                    <ProtectedRoute>
                        <AnalyzeLyrics />
                    </ProtectedRoute>
                } 
            />
            <Route path="/" element={<Navigate to="/currently_playing" />} />
            {/* Directly redirect unknown routes to /currently_playing */}
            <Route path="*" element={<Navigate to="/currently_playing" />} />
        </Routes>
    );
}

function App() {
    return (
        <Router>
            <AuthProvider>
                <Suspense fallback={<div>กำลังโหลด...</div>}>
                    <AppRoutes />
                </Suspense>
            </AuthProvider>
        </Router>
    );
}

export default App;

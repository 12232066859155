// frontend/src/contexts/AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState({
        isAuthenticated: false,
        user: null
    });

    const logout = async () => {
        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/auth/logout`, {}, {
                withCredentials: true
            });
            setAuth({ isAuthenticated: false, user: null });
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    useEffect(() => {
        const checkAuthStatus = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/auth/status`, {
                    withCredentials: true
                });
                if (response.data.authenticated) {
                    setAuth({
                        isAuthenticated: true,
                        user: response.data.user
                    });
                } else {
                    setAuth({
                        isAuthenticated: false,
                        user: null
                    });
                }
            } catch (error) {
                console.error('Error checking auth status:', error);
                setAuth({
                    isAuthenticated: false,
                    user: null
                });
            }
        };

        checkAuthStatus();
    }, []);

    return (
        <AuthContext.Provider value={{ auth, setAuth, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
